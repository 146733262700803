import React from 'react'
import { Link } from 'gatsby'
import { Layout, SEO } from '@components'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <h1 className="txt-poster txt-center">Oh no.</h1>
    <p className="txt-center">You just hit a page that doesn&#39;t exist... the sadness.</p>
    <Link to="/" className="txt-center">← Go back to the beginning?</Link>
  </Layout>
)

export default NotFoundPage
